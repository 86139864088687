import "./Solution.css";
import React from "react";
import arrow from "../../../assets/icons/arrow_white.svg";
import { Link } from "react-router-dom";

function Solution({ title, id }) {
  return (
    <Link className="solution-container" to={`/solutions?solution_id=${id}`}>
      <div className="solution-title">{title}</div>
      <div className="solution-button">
        <img src={arrow} alt="arrow" /> En savoir plus
      </div>
    </Link>
  );
}

export default Solution;
