import TimelineItem from "../../molecules/timelineItem/TimelineItem";
import "./TimelineContainer.css";
import React from "react";

function TimelineContainer() {
  return (
    <div className="timeline-container">
      <TimelineItem
        title="Briefe Client"
        alignLeft
        description="Le client nous confie son briefe et décrit les enjeux stratégiques et commerciaux de son entreprise."
        showLine={false}
      />
      <TimelineItem
        title="Définition des objectifs du client"
        description="Nous définissons ensemble les objectifs précis en mettant en lumière des indicateurs de performance (génération de leads, coaching commercial, restructuration d’équipe commerciale, adaptation de l’offre de services au marché, etc.)."
      />
      <TimelineItem
        title="Recommandation commerciale et opérationnelle"
        alignLeft
        description="Grâce au briefe du client récupéré et aux objectifs définis, nous rédigeons une recommandation adaptée aux besoins détectés, avec des solutions concrètes et opérationnelles incluant des objectifs commerciaux et des indicateurs de performance."
      />
      <TimelineItem
        title="Mise en place opérationnelle"
        description="Nous mettons en place la solution préconisée au sein de votre structure avec un rétroplanning et des deadlines. Nous veillons à ce que la solution soit bien intégrée dans l’ensemble des équipes pour renforcer la culture d’entreprise."
      />
      <TimelineItem
        alignLeft
        title="Suivi des indicateurs de performance et garantie du ROI"
        description="Nous suivons avec nos clients les indicateurs de performance pour nous assurer de l’efficacité de la solution mise en place. Nous réajustons opérationnellement le dispositif le cas échéant."
      />
      <TimelineItem
        title="Suivi longitudinal"
        showLine={false}
        description="Après la prestation, nous fixons des réunions de suivi pour contrôler la performance sur le long terme."
      />
    </div>
  );
}

export default TimelineContainer;
