import "./Card.css";
import React from "react";
import arrow from "../../../assets/icons/arrow.svg";

function Card({ title, children, maxWidth = "100%", className = "", id, bottomContent }) {
  return (
    <div className={`card-container ${className}`} style={{ maxWidth: maxWidth }} id={id} bottom-content={bottomContent}>
      {title && (
        <>
          <img src={arrow} alt="arrow" />
          <h3>{title}</h3>
        </>
      )}
      {children}
    </div>
  );
}

export default Card;
