import React from "react";
import { Helmet } from "react-helmet";

function Rgpd() {
  return (
    <div className="page-container text-container">
    <Helmet>
      <title>Politique de confidentialité de vos données</title>
      <meta
        name="description"
        content="Découvrez la politique de confidentialité d'EstimerMonCommerce.fr pour en savoir plus sur l'utilisation du site"
      />
      <link rel="canonical" href="https://www.vitaleperformancesante.fr/rgpd" />
      <meta
        property="og:title"
        content="Politique de confidentialité de vos données"
      />
      <meta
        property="og:description"
        content="Découvrez la politique de confidentialité d'EstimerMonCommerce.fr pour en savoir plus sur l'utilisation du site"
      />
      </Helmet>
      <h1>Réglement général sur la protection des données</h1>
      <p>
        Le RGPD est un nouvel ensemble de lois adopté par l&#39;Union européenne en mai 2018. Les
        informations suivantes sont pertinentes pour ceux d&#39;entre vous qui sont soumis aux lois
        de l&#39;UE.
        <br />
        Conformément au RGPD, vous avez les droits suivants&nbsp;:
      </p>
      <h2>Le Droit d&#39;Accès&nbsp;:</h2>
      <p>
        Vous avez le droit de demander l&#39;accès à toutes vos données personnelles que nous avons
        stockées, et de demander comment vos données sont utilisées par notre entreprise après leur
        collecte. Nous devons fournir une copie des données personnelles gratuitement sous format
        électronique. Pour plus d&#39;informations, veuillez lire notre Politique de
        confidentialité.
      </p>
      <h2>Le Droit à l&#39;Oubli&nbsp;:</h2>
      <p>
        Si vous n&#39;êtes plus un client de notre entreprise, ou si vous retirez votre consentement
        à notre entreprise pour utiliser vos données personnelles, vous avez le droit de faire
        supprimer vos données. Toute personne se désabonnant de l&#39;une de nos newsletters ou
        notifications de mises à jour par e-mail cessera immédiatement de recevoir des e-mails de
        notre part. De plus, nous supprimons périodiquement toutes les données de ceux qui se
        désabonnent sur une base hebdomadaire. Si vous souhaitez nous contacter davantage pour faire
        supprimer vos données, laissez-nous simplement un message à l&#39;adresse suivante&nbsp;:
        <a href="mailto:guillaume.robertet2@gmail.com">guillaume.robertet2@gmail.com</a>
      </p>
      <h2>Le Droit à la Portabilité des Données&nbsp;:</h2>
      <p>
        Vous avez le droit de transférer vos données depuis notre site Web vers tout autre
        fournisseur de services. Vous pouvez le faire en recherchant vos coordonnées (voir &quot;Le
        Droit d&#39;Accès&quot; ci-dessus) et en transférant ces informations. Ou vous pouvez
        contacter notre service client si vous avez d&#39;autres demandes.
      </p>
      <h2>Le Droit d&#39;Être Informé&nbsp;:</h2>
      <p>
        Cela concerne votre droit d&#39;être informé de toute collecte de données.
        <br />
        Ici, nous vous informons de la manière dont nous collectons des données&nbsp;: Nous utilisons
        Google Analytics pour voir d&#39;où viennent nos visiteurs (sites Web, pays, expressions de
        recherche). Cela n&#39;est pas identifiable par l&#39;individu et est collecté dans son
        ensemble.
        <br />
        Pour vous inscrire à l&#39;une de nos newsletters, nous collectons votre nom et votre
        adresse e- mail.
        <br />
        Si vous êtes membre de l&#39;un de nos produits, nous collecterons également un nom
        d&#39;utilisateur et un mot de passe pour vous.
        <br />
        Nous stockons également la date à laquelle vous vous êtes inscrit, ainsi que votre adresse
        IP. Nous devons stocker ces informations comme preuve que vous avez consenti à vous inscrire
        à notre newsletter. Vous pouvez vous désabonner à tout moment en cliquant sur le lien de
        désinscription contenu dans chacune de nos newsletters. Si vous êtes abonné à l&#39;une de
        nos newsletters, nous collectons également des données sur les taux d&#39;ouverture et les
        taux de clics à partir de nos e-mails, afin de savoir quels sujets intéressent le plus nos
        lecteurs. Lors de l&#39;inscription à nos newsletters, ou mises à jour pour les membres,
        etc., nous donnons des détails explicites sur ces pages d&#39;inscription, de sorte que
        votre consentement doit être donné librement pour rejoindre notre liste. Vous pouvez
        également vous désabonner à tout moment. Nous collectons également des données pour
        déterminer quelles sont nos pages les plus visitées et, lorsque des ventes sont réalisées,
        nous collectons ces chiffres de ventes pour déterminer quelles pages génèrent des ventes
        plus élevées. Cela nous permet de vous fournir plus de contenu désiré par le marché, tout en
        développant notre entreprise de manière axée sur le marché.
        <br />
      </p>
      <h2>Le Droit de Rectification des Informations&nbsp;:</h2>
      Si vous souhaitez mettre à jour/corriger vos informations avec nous, contactez-nous simplement
      et faites-nous savoir ce qui doit être changé&nbsp;:{" "}
      <a href="mailto:guillaume.robertet2@gmail.com">guillaume.robertet2@gmail.com</a>
      <h2>Le Droit à la Limitation du Traitement&nbsp;:</h2>
      <p>
        Vous pouvez demander que vos données ne soient pas utilisées pour le traitement. Les données
        peuvent rester en place sans être utilisées, ou peuvent être supprimées sur votre demande.
      </p>
      <h2>Le Droit de S&#39;Opposer&nbsp;:</h2>
      <p>
        Vous avez le droit de vous opposer au traitement de vos données à des fins de marketing
        direct. Nous le précisons très clairement au début de toute communication avec nous (comme
        l&#39;inscription à une newsletter), et vous pouvez vous désabonner à tout moment en
        cliquant sur un lien dans l&#39;un de nos e-mails.
      </p>
      <h2>Le Droit à Être Notifié&nbsp;:</h2>
      <p>
        Si une violation de données compromettant vos données personnelles se produit, vous serez
        informé dans les 72 heures suivant notre première prise de conscience de la violation.
      </p>
    </div>
  );
}

export default Rgpd;
