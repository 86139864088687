import React from "react";
import { Helmet } from "react-helmet";

function MentionsLegales() {
  return (
    <div className="page-container text-container">
      <Helmet>
        <title>Mentions légales applicables au 30 septembre 2024</title>
        <meta
          name="description"
          content="Découvrez les mentions légales de Vitale Performance Santé pour découvrir l'éditeur, le concepteur et l'hébergeur du site"
        />
        <link rel="canonical" href="https://www.vitaleperformancesante.fr/mentions-legales" />
        <meta property="og:title" content="Mentions légales applicables au 30 septembre 2024" />
        <meta
          property="og:description"
          content="Découvrez les mentions légales de Vitale Performance Santé pour découvrir l'éditeur, le concepteur et l'hébergeur du site"
        />
      </Helmet>
      <h1>Mentions légales</h1>
      <h2>Présentation du Site</h2>
      <p>
        En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans
        l’économie numérique, les différents intervenants du site dans le cadre de sa réalisation et
        de son suivi&nbsp;
      </p>
      <ul>
        <li>
          <span className="bold">Propriétaire&nbsp;:</span>&nbsp;Vitale Performance Santé - , EI
          auteur au capital de €. Le propriétaire du site internet est joignable à cette adresse :{" "}
          <a href="mailto:guillaume.robertet2@gmail.com">guillaume.robertet2@gmail.com</a>
        </li>
        <li>
          <span className="bold" Adresse>
            &nbsp;:
          </span>
          &nbsp;17, Avenue Joseph Froment 92250 La Garenne Colombes
        </li>
        <li>
          <span className="bold">Créateur du site&nbsp;:</span>&nbsp;Guillaume ROBERTET
        </li>
        <li>
          <span className="bold">Responsable publication&nbsp;:</span>&nbsp;Guillaume Robertet -{" "}
          <a href="mailto:guillaume.robertet2@gmail.com">guillaume.robertet2@gmail.com</a>
        </li>
        <li>
          <span className="bold">Webmaster&nbsp;:</span>&nbsp;Pablo Guinard -{" "}
          <a href="mailto:guinardpablo@gmail.com">guinardpablo@gmail.com</a>
        </li>
        <li>
          <span className="bold">Hébergeur&nbsp;:</span>&nbsp;OVH 2, Rue Kellermann 59100 Roubaix
        </li>
        <li>
          <span className="bold">Crédits&nbsp;:</span>&nbsp;Merci à Pablo Guinard et Sybille Guinard
          pour leur précieuse contribution.
        </li>
      </ul>
      <h2>Conditions d'utilisation</h2>
      <p>
        L’utilisation du site implique l’acceptation pleine et entière des conditions générales
        d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être
        modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les
        consulter de manière régulière.
        <br />
        Le site est mis à jour régulièrement par Guillaume Robertet. De la même façon,{" "}
        <span className="bold">les mentions légales peuvent être modifiées à tout moment</span>
        &nbsp;: elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus
        souvent possible afin d’en prendre connaissance.
      </p>
      <h2>Services fournis</h2>
      <p>
        Guillaume Robertet, s’efforce de fournir sur le site des informations aussi précises que
        possible.
        <br />
        Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des
        carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires
        qui lui fournissent ces informations. Tous les informations indiquées sur le site
        <span className="underline">www.vitaleperformancesante.fr</span> sont données à titre
        indicatif, et sont susceptibles d’évoluer. Aussi, toutes les informations indiquées sur le
        site Site
        <span className="underline">www.vitaleperformancesante.fr</span> sont données à titre
        indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.
      </p>
      <p>
        Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de
        bien vouloir le signaler par email, à l’adresse{" "}
        <a href="mailto:guillaume.robertet2@gmail.com">guillaume.robertet2@gmail.com</a>, en
        décrivant le problème de la manière la plus précise possible (vous vous trouver sur un
        téléphone, une tablette ou bien un ordinateur ; nom de la page qui pose problème, type de
        système d’exploitation, navigateur utilisé,…).
        <br /> Guillaume Robertet n’est en aucun cas responsable de l’utilisation faite de ces
        informations, et de tout préjudice direct ou indirect pouvant en découler.
      </p>
      <h2>Propriété intellectuelle et contrefaçon</h2>
      <p>
        Vitale Performance Santé est le propriétaire des droits de propriété intellectuelle ou
        détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les
        textes, images, graphismes, logo, icônes, sons, logiciels.
        <br /> Toute reproduction, représentation, modification, publication, distribution,
        retransmission, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou
        le procédé utilisé, est interdite, sauf autorisation écrite préalable de :
        <span className="underline">www.vitaleperformancesante.fr</span>.
        <br /> Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il
        contient, par quelque procédé que ce soit, sera considérée comme constitutive d’une
        contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du
        Code de Propriété Intellectuelle.
        <br /> Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la
        responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus
        copiés pourraient intenter une action en justice à votre encontre.
      </p>
      <h2>Limitations et responsabilités</h2>
      <p>
        L’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne
        contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
        <br />
        Guillaume Robertet ne pourra être tenue responsable des dommages directs et indirects causés
        au matériel de l’utilisateur, lors de l’accès au site{" "}
        <span className="underline">www.vitaleperformancesante.fr</span>, et résultant soit de
        l’utilisation d’un matériel ne répondant pas aux spécifications indiquées ci-dessus, soit de
        l’apparition d’un bug ou d’une incompatibilité.
        <br />
        Guillaume Robertet ne pourra également être tenue responsable des dommages indirects (tels
        par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du
        site <span className="underline">www.vitaleperformancesante.fr</span>.
        <br />
        Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la
        disposition des utilisateurs. Guillaume Robertet se réserve le droit de supprimer, sans mise
        en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la
        législation applicable en France, en particulier aux dispositions relatives à la protection
        des données.
        <br />
        Le cas échéant, Guillaume Robertet se réserve aussi la possibilité de mettre en cause la
        responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère
        diffamant, raciste, injurieux ou pornographique, quel que soit le support utilisé (texte,
        vidéo, photographie…).
        <br />
      </p>
      <h3>Gestion des données personnelles. </h3>
      <p>
        En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6
        janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la
        Directive Européenne du 24 octobre 1995.
        <br />A l’occasion de l’utilisation du site{" "}
        <span className="underline">www.vitaleperformancesante.fr</span>, peuvent être recueillies :
        l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé à ce site, le
        fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.
        <br />
        En tout état de cause Guillaume Robertet ne collecte des informations personnelles relatives
        à l’utilisateur que pour le besoin de certains services proposés par le site{" "}
        <span className="underline">www.vitaleperformancesante.fr</span>. L’utilisateur fournit ces
        informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur
        saisie. Il est alors précisé à l’utilisateur du site{" "}
        <span className="underline">www.vitaleperformancesante.fr</span> l’obligation ou non de
        fournir ces informations.
        <br />
        Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978
        relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit
        d’accès, de rectification et d’opposition aux données personnelles le concernant, en
        effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec
        signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être
        envoyée.
        <br />
        Aucune information personnelle de l’utilisateur du site{" "}
        <span className="underline">www.vitaleperformancesante.fr</span> n’est publiée à l’insu de
        l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.
        Seule l’hypothèse du rachat de Guillaume Robertet et/ou du site internet de ses droits
        permettrait la transmission des dites informations à l’éventuel acquéreur qui serait à son
        tour tenu de la même obligation de conservation et de modification des données vis à vis de
        l’utilisateur du site.
        <br />
        Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998
        transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases
        de données.
        <br />
      </p>
      <h2>Liens hypertextes et cookies</h2>
      <p>
        Le site <span className="underline">www.vitaleperformancesante.fr</span> peut contenir un
        certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de
        Guillaume Robertet.
        <br />
        Cependant, Guillaume Robertet n’a pas la possibilité de vérifier le contenu des sites ainsi
        visités, et n’assumera en conséquence aucune responsabilité de ce fait.
        <br />
        La navigation sur le site <span className="underline">
          www.vitaleperformancesante.fr
        </span>{" "}
        est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
        <br />
        Un cookie est un fichier de petite taille, qui ne permet pas l’identification de
        l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un
        ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation
        ultérieure sur le site, et ont également vocation à permettre diverses mesures de
        fréquentation.
        <br />
        Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains
        services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante,
        pour refuser l’installation des cookies&nbsp;:
      </p>
      <ul>
        <li>
          <span className="bold">Sous Internet Explorer&nbsp;:</span>&nbsp; onglet outil
          (pictogramme en forme de rouage en haut a droite) / options internet. Cliquez sur
          Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.
        </li>
        <li>
          <span className="bold">Sous Firefox&nbsp;:</span>&nbsp;en haut de la fenêtre du
          navigateur, cliquez sur le bouton Firefox, puis aller dans l’onglet Options. Cliquer sur
          l’onglet Vie privée.
        </li>
        <li>
          <span className="bold">Paramétrez les Règles de conservation sur&nbsp;:</span>&nbsp;
          utiliser les paramètres personnalisés pour l’historique. Enfin décochez-la pour désactiver
          les cookies.
        </li>
        <li>
          <span className="bold">Sous Safari&nbsp;:</span>&nbsp;Cliquez en haut à droite du
          navigateur sur le pictogramme de menu (symbolisé par un rouage). Sélectionnez Paramètres.
          Cliquez sur Afficher les paramètres avancés. Dans la section “Confidentialité”, cliquez
          sur Paramètres de contenu. Dans la section “Cookies”, vous pouvez bloquer les cookies.
        </li>
        <li>
          <span className="bold">Sous Chrome&nbsp;:</span>&nbsp; Cliquez en haut à droite du
          navigateur sur le pictogramme de menu (symbolisé par trois lignes horizontales).
          Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section
          “Confidentialité”, cliquez sur préférences. Dans l’onglet “Confidentialité”, vous pouvez
          bloquer les cookies.
        </li>
      </ul>
      <p>
        Les liens hypertextes mis en place dans le cadre du présent site internet en direction
        d'autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité
        de Guillaume Robertet.
        <br />
        Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule
        responsabilité. En conséquence, Guillaume Robertet ne saurait être tenu responsable d'un
        quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de
        données consécutives au téléchargement.
      </p>
      <h2>Droit applicable et attribution de juridiction</h2>
      <p>
        Les présentes conditions du site{" "}
        <span className="underline">www.vitaleperformancesante.fr</span> sont régies par les lois
        françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de
        l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le
        siège social de la société Guillaume Robertet. La langue de référence, pour le règlement de
        contentieux éventuels, est le français.
      </p>
      <h2>LES PRINCIPALES LOIS CONCERNEES</h2>
      <p>
        Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004
        relative à l’informatique, aux fichiers et aux libertés.
        <br />
        Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.
      </p>
      <h2>Lexique</h2>
      <ul>
        <li>
          <span className="bold">Propriétaire du site&nbsp;:</span>&nbsp;Responsable légal des
          informations contenues dans le site internet
        </li>
        <li>
          <span className="bold">Webmaster&nbsp;:</span>&nbsp;Personne en charge du maintien
          technique et des mises à jour techniques du site internet
        </li>
        <li>
          <span className="bold">Responsable publication&nbsp;:</span>&nbsp;Personne en charge de la
          mise à jour des contenus (textes, visuels, multimédia, etc.) sur le site internet
        </li>
        <li>
          <span className="bold">Hébergeur&nbsp;:</span>&nbsp; Espace de stockage du site internet
        </li>
        <li>
          <span className="bold">Utilisateur&nbsp;:</span>&nbsp;Internaute se connectant, utilisant
          le site susnommé.
        </li>
        <li className="bold">
          Informations personnelles&nbsp;:&nbsp; « les informations qui permettent, sous quelque
          forme que ce soit, directement ou non, l’identification des personnes physiques auxquelles
          elles s’appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
        </li>
      </ul>
    </div>
  );
}

export default MentionsLegales;
