import './Header.css'
import logo from '../../../assets/logos/logo_no_baseline.svg'
import {Link} from 'react-router-dom'

import React from 'react'

function Header() {
  return (<nav>
    <Link to="/"><img src={logo} alt="Vitale Santé Performance" /></Link>
    <div className='header-links'>
        <Link to="/solutions">Solutions</Link>
        <Link to="/qui-sommes-nous">Qui sommes-nous</Link>
        <Link className='gradient-button' to="/contact">Demander une démo</Link>
    </div>
  </nav>
  )
}

export default Header