import "./Input.css";
import React, { Fragment } from "react";

// useForm = {
//   register: () => {},
//   errors: {}
// };
function Input({ label, id, validation, useForm, placeholder }) {
  return (
    <div className="input-container">
      <label htmlFor={id}>{label}</label>
      <input id={id} placeholder={placeholder} type="text" {...useForm.register(id, validation)} />
      <div className={`error-container ${useForm.errors?.[id]?.message ? "opened" : ""}`}>
        {useForm.errors?.[id]?.message.split(" ").map((word, index) => (<Fragment key={index}>{word}&nbsp;</Fragment>))}
      </div>
    </div>
  );
}

export default Input;
