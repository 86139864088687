import "./TimelineItem.css";
import React from "react";
import arrow from "../../../assets/icons/arrow.svg";
import Card from "../card/Card";

function TimelineItem({ title, alignLeft, description, showLine = true }) {
  return (
    <div className={`timeline-item-container ${alignLeft ? "align-left" : "align-right"} ${showLine ? "with-line" : ""}`}>
      <img src={arrow} alt="arrow" />
      {showLine && <div className="timeline-line" />}
      <h3>{title}</h3>
      <Card>
        <p>{description}</p>
      </Card>
    </div>
  );
}

export default TimelineItem;
