import "./Footer.css";
import React from "react";
import logo from "../../../assets/logos/logo_black_white.svg";
import { Link } from "react-router-dom";
import instagram from "../../../assets/icons/instagram.svg";
import linkedin from "../../../assets/icons/linkedin.svg";

function Footer() {
  return (
    <div className="footer-container">
      <footer>
        <img src={logo} className="footer-logo" alt="Vitale Santé Performance" />
        <div className="footer-nav">
          <div>
            <Link to="/solutions">Solutions</Link>
            <Link to="/qui-sommes-nous">Qui sommes-nous</Link>
            <Link to="/contact">Contact</Link>
          </div>
          <div>
            <Link to="/mentions-legales">Mentions légales</Link>
            <Link to="/rgpd">Règlement général sur la protection des données</Link>
          </div>
          2024 - Tous droits réservés
        </div>
        <div className="footer-contact">
          <div>
            {/* <Link to="//TODO" rel="nofollow" target="_blank">
              <img src={instagram} alt="instagram" />
            </Link> */}
            <Link to="https://www.linkedin.com/in/guillaume-robertet-b867727/" rel="nofollow" target="_blank">
              <img src={linkedin} alt="linkedin" />
            </Link>
          </div>
          <Link to="mailto:guillaume.robertet@vitaleperformancesante.fr">
            guillaume.robertet@vitaleperformancesante.fr
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
