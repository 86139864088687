import { selectValidation } from "../../../Utils";
import "./Select.css";
import React from "react";

function Select({ label, id, useForm, options }) {
  return (
    <div className="input-container select-container">
      <label htmlFor={id}>{label}</label>
      <label htmlFor={id} className="select">
        <select id={id} {...useForm.register(id, selectValidation)}>
          <option value="_null">Choisir une option</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span onClick={() => document.getElementById(id).click()}></span>
        <span onClick={() => document.getElementById(id).click()}></span>
      </label>
      <div className={`error-container ${useForm.errors?.[id]?.message ? "opened" : ""}`}>
        Ce&nbsp;champ&nbsp;est&nbsp;obligatoire
      </div>
    </div>
  );
}

export default Select;
