import "./Solutions.css";
import React, { useEffect, useState } from "react";
import heroImg from "../../assets/images/solutions_hero.webp";
import Card from "../../components/molecules/card/Card";
import { Link, replace, useSearchParams } from "react-router-dom";
import { solutions } from "../../Utils";
import { Helmet } from "react-helmet";

function Solutions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [solutionsExpansion, setSolutionsExpansion] = useState(
    solutions.map((solution) => {
      return { id: solution.id, isExpanded: false };
    })
  );

  useEffect(() => {
    if (!searchParams.has("solution_id")) return;

    const solutionId = searchParams.get("solution_id");

    setSolutionsExpansion((solutions) =>
      solutions.map((solution) =>
        solution.id == solutionId ? { ...solution, isExpanded: true } : solution
      )
    );
    const card = document.getElementById("solution_" + solutionId);

    if (card)
      window.scrollTo({ left: 0, top: card.getBoundingClientRect().top, behavior: "smooth" });

    searchParams.delete("solution_id");
    setSearchParams(searchParams, { replace: true });
  }, []);

  function toggleSolutionExpansion(solutionId, isExpanded) {
    setSolutionsExpansion(
      solutionsExpansion.map((solution) => {
        if (solution.id === solutionId) {
          return { ...solution, isExpanded: isExpanded };
        }
        return solution;
      })
    );
  }

  function isSolutionExpanded(solutionId, solutionsExpansion) {
    return solutionsExpansion.find((solution) => solutionId === solution.id)?.isExpanded;
  }

  function formatParagraph(paragraph) {
    return paragraph.replaceAll(" ?", "\u00A0?").replaceAll(" !", "\u00A0!").replaceAll(" :", "\u00A0:");
  }

  return (
    <div className="page-container solutions-page-container">
    <Helmet>
      <title>Les solutions proposées pour améliorer l’efficacité commerciale</title>
      <meta
        name="description"
        content="Des audits commerciaux, des recommandations opérationnelles, des missions de coaching commercial , des solutions pour booster la génération de Leads, des offres sur mesures pour booster votre C.A"
      />
      <link rel="canonical" href="https://www.vitaleperformancesante.fr/solutions" />
      <meta
        property="og:title"
        content="Les solutions proposées pour améliorer l’efficacité commerciale"
      />
      <meta
        property="og:description"
        content="Des audits commerciaux, des recommandations opérationnelles, des missions de coaching commercial , des solutions pour booster la génération de Leads, des offres sur mesures pour booster votre C.A"
      />
    </Helmet>
      <section className="hero-section">
        <div>
          <h1>Nos solutions</h1>
          <p>
            Nous avons interrogé 15 start-up, 10 fonds d’investissement, 5 accélérateurs, des Pme
            spécialisées dans la e-Santé, des grands groupes et 8 Cabinets de recrutements pour
            définir un cahier des charges des besoins opérationnels. Nous avons créé des offres sur
            mesure adaptées à chaque besoin.
          </p>
        </div>
        <img src={heroImg} alt="solutions vitale santé performance" />
      </section>
      <section className="solutions-container">
        {solutions.map((solution) => (
          <Card key={solution.id} id={"solution_" + solution.id} title={solution.title}>
            <div className="solution-content">
              <div className="solution-description">
                {solution.description && (
                  <p>
                    <span>
                      Pour qui :<br />
                    </span>
                    {formatParagraph(solution.description)}
                  </p>
                )}
                {solution.target && (
                  <p>
                    <span>
                      Pour qui :<br />
                    </span>
                    {formatParagraph(solution.target)}
                  </p>
                )}
                {solution.challenge && (
                  <p>
                    <span>
                      L'enjeu&nbsp;:
                      <br />
                    </span>
                    {formatParagraph(solution.challenge)}
                  </p>
                )}
                <div
                  className={`solution-extra ${
                    isSolutionExpanded(solution.id, solutionsExpansion) ? "expanded" : ""
                  }`}
                >
                  {solution.solution && (
                    <p>
                      <span>
                        La solution&nbsp;:
                        <br />
                      </span>
                      {formatParagraph(solution.solution)}
                    </p>
                  )}
                  {solution.process && (
                    <p>
                      {solution.id !== 6 && (
                        <span>
                          Le processus&nbsp;:
                          <br />
                        </span>
                      )}
                      {formatParagraph(solution.process)}
                    </p>
                  )}
                  {solution.tarif && (
                    <p>
                      <span>
                        Le tarif&nbsp;:
                        <br />
                      </span>
                      {formatParagraph(solution.tarif)}
                    </p>
                  )}
                </div>
              </div>
              <div className="solution-buttons">
                {isSolutionExpanded(solution.id, solutionsExpansion) ? (
                  <>
                    <button
                      className="light-button"
                      onClick={() => {
                        toggleSolutionExpansion(solution.id, false);
                      }}
                    >
                      Fermer
                    </button>
                    <Link className="dark-button" to={`/contact?solution_id=${solution.id}`}>
                      Demander une démo
                    </Link>
                  </>
                ) : (
                  <button
                    className="dark-button"
                    onClick={() => {
                      toggleSolutionExpansion(solution.id, true);
                    }}
                  >
                    En savoir plus
                  </button>
                )}
              </div>
            </div>
          </Card>
        ))}
      </section>
    </div>
  );
}

export default Solutions;
