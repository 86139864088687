import { BrowserRouter, Routes, Route, Navigate, ScrollRestoration } from "react-router-dom";
import Header from "./components/organisms/header/Header";
import Footer from "./components/organisms/footer/Footer";
import Contact from "./pages/contact/Contact";
import Solutions from "./pages/solutions/Solutions";
import Description from "./pages/description/Description";
import Home from "./pages/home/Home";
import ScrollToTop from "./components/molecules/ScrollToTop";
import MentionsLegales from "./pages/mentionsLegales/MentionsLegales";
import Rgpd from "./pages/rgpd/Rgpd";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/qui-sommes-nous" element={<Description />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/rgpd" element={<Rgpd />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
