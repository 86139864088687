import Card from "../card/Card";
import "./ConvincingCard.css";
import React from "react";

function ConvincingCard({ title, content, bottomContent }) {
  return (
    <div bottom-content={bottomContent} className="convincing-card-container">
      <Card className="convincing-card" title={title}>
        <p>{content}</p>
        <p className="mobile-content">{bottomContent}</p>
      </Card>
    </div>
  );
}

export default ConvincingCard;
