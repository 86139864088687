import "./Description.css";
import React from "react";
import heroImg from "../../assets/images/description_hero.webp";
import analyseImg from "../../assets/images/description_analyse.webp";
import legitimiteImg from "../../assets/images/description_legitimite.webp";
import solutionImg from "../../assets/images/description_solution.webp";
import Card from "../../components/molecules/card/Card";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Description() {
  return (
    <div className="description-container">
    <Helmet>
      <title>Pourquoi faire appel à Vitale Performance Santé pour booster son C.A</title>
      <meta
        name="description"
        content="Légitimité avec expertise dans la Santé de plus de 20 ans (postes de Directeur Commercial), et connaissance  problématiques commerciales et opérationnelles des entreprises"
      />
      <link rel="canonical" href="https://www.vitaleperformancesante.fr/qui-sommes-nous" />
      <meta
        property="og:title"
        content="Pourquoi faire appel à Vitale Performance Santé pour booster son C.A"
      />
      <meta
        property="og:description"
        content="Légitimité avec expertise dans la Santé de plus de 20 ans (postes de Directeur Commercial), et connaissance  problématiques commerciales et opérationnelles des entreprises"
      />
      </Helmet>
      <section className="description-hero page-container">
        <h1>Qui sommes-nous&nbsp;?</h1>
        <div>
          <img src={heroImg} alt="description Vitale Performance Santé" />
          <p>
            Titulaire d’un Master 2 en Marketing et Stratégie à l’IAE, j’ai occupé pendant plus de
            20 ans des postes de directeur commercial dans le domaine de la santé digitale et le
            médical. <br />
            J’ai travaillé à la fois pour des start-up, des PME et des grands groupes pour définir
            la stratégie et accompagner la croissance de ces entreprises.
          </p>
        </div>
      </section>
      <section className="description-legitimite">
        <div className="page-container">
          <img src={legitimiteImg} alt="légitimité Vitale Performance Santé" />
          <div>
            <h2>Légitimité</h2>
            <p>
              Membre du comité de direction de chaque entreprise dans laquelle j’ai œuvré, j’ai une
              très bonne connaissance de l’écosystème de la santé et des enjeux stratégiques et
              opérationnels de ces structures.
            </p>
            <p>
              En tant que directeur commercial, j’ai été au cœur du réacteur de ces entreprises de
              santé. J’ai donc été confronté à la plupart des problématiques opérationnelles et
              commerciales.
            </p>
            <p>
              Ayant managé et restructuré des équipes commerciales (de tailles variées) en utilisant
              des stratégies commerciales adaptées, je suis à même de vous accompagner dans vos
              performances commerciales, que ce soit pour des solutions et services digitaux à
              destination des professionnels de santé, de l’industrie pharmaceutique ou des
              établissements de santé.
            </p>
          </div>
        </div>
      </section>
      <section className="description-analyse page-container">
        <div>
          <h2>Analyse du besoin</h2>
          <p>
            Après avoir interviewé une quinzaine de start-up dans la santé, des fonds
            d’investissement et des accélérateurs, des PME spécialisés dans la Santé, des Cabinet de
            recrutement j’ai fait le même constat. Les entreprises en santé ont besoin d’être
            accompagnées pour booster leurs performances commerciales et passer du statut de
            start-up à scale-up.
          </p>
          <p>
            Souvent par manque de temps, de connaissances commerciales ou par peur, les dirigeants
            de start-up dans la santé ont du mal à recruter, à structurer les équipes commerciales,
            à définir la bonne stratégie de vente, les bons objectifs et indicateurs de performance,
            à générer des leads et à se confronter tout simplement au marché.
          </p>
          <p>Ce constat peut être fait aussi à une moindre échelle avec des PME.</p>
          <p>
            Ces entreprises ont aussi (même si elles ne l’avouent pas toujours) besoin d’être coachées
            et guidées commercialement et opérationnellement pour accélérer les ventes et booster
            les performances commerciales.
          </p>
        </div>
        <img src={analyseImg} alt="analyse besoin Vitale Performance Santé" />
      </section>
      <section className="description-solution">
        <div className="page-container">
          <img src={solutionImg} alt="solution Vitale Performance Santé" />
          <Card title="La solution apportée" className="description-card">
            <p>
              Vitale Performance Santé propose des solutions personnalisées pour répondre à chaque
              enjeu opérationnel et commercial et accompagner la croissance de ses clients.
            </p>
            <p>
              À travers des missions d’audit commercial et opérationnel, de coaching, des solutions
              de génération de leads, de recrutement et de restructuration d’équipe commerciale,
              Vitale Performance Santé booste la performance et la santé de votre entreprise.
            </p>
            <Link className="gradient-button" to="/solutions">
              Découvrez nos solutions
            </Link>
          </Card>
        </div>
      </section>
    </div>
  );
}

export default Description;
