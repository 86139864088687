import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Input from "../../components/molecules/input/Input";
import Select from "../../components/molecules/select/Select";
import phoneImg from "../../assets/images/contact_phone.webp";
import { defaultValidation, emailValidation, phoneValidation } from "../../Utils";
import "./Contact.css";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { solutions } from "../../Utils";
import { PopupWidget } from "react-calendly";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

function Contact() {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const inputForm = { register, errors };
  const formRef = useRef();
  const dateInputRef = useRef();

  useEffect(() => {
    if (!searchParams.has("solution_id")) return;

    setValue("solution", searchParams.get("solution_id"));
    searchParams.delete("solution_id");
    setSearchParams(searchParams, { replace: true });
  }, []);

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  async function formSubmit(values) {
    if (!captchaVerified) {
      toast.error("Veuillez valider le reCAPTCHA avant de soumettre le formulaire.", {
        position: "bottom-right"
      });
      return;
    }

    try {
      dateInputRef.current.value = new Date().toLocaleString();

      emailjs
        .sendForm("service_rv1gd7f", "template_1syg7h4", formRef.current, "rVSZxYJGmf3Z4e9d9")
        .then(
          (result) => {
            toast.success(
              "Votre demande a été prise en compte, Nous allons vous contacter rapidement.",
              {
                position: "bottom-right"
              }
            );
          },
          (error) => {
            toast.error("Une erreur est survenue, veuillez réessayer.", {
              position: "bottom-right"
            });
          }
        );
    } catch (error) {
      toast.error("Une erreur est survenue, veuillez réessayer.", {
        position: "bottom-right"
      });
    }
  }

  return (
    <div className="page-container">
    <Helmet>
      <title>Contactez-nous pour une démonstration de nos solutions</title>
      <meta
        name="description"
        content="Contactez nous via notre formulaire, par mail, par téléphone, prenez rendez vous sur Calendly pour une démo de nos offres de service et de notre approche commerciale"
      />
      <link rel="canonical" href="https://www.vitaleperformancesante.fr/contact" />
      <meta
        property="og:title"
        content="Contactez-nous pour une démonstration de nos solutions"
      />
      <meta
        property="og:description"
        content="Contactez nous via notre formulaire, par mail, par téléphone, prenez rendez vous sur Calendly pour une démo de nos offres de service et de notre approche commerciale"
      />
    </Helmet>
      <h1>Formulaire de contact</h1>
      <div className="contact-container">
        <img src={phoneImg} className="contact-img" />
        <form className="contact-form" onSubmit={handleSubmit(formSubmit)} ref={formRef}>
          <input type="hidden" name="datetime" ref={dateInputRef} />
          <div className="row-1000">
            <Input
              id="lastname"
              label="Nom *"
              validation={defaultValidation}
              useForm={inputForm}
            />
            <Input
              id="firstname"
              label="Prénom *"
              validation={defaultValidation}
              useForm={inputForm}
            />
          </div>
          <div className="row-1000">
            <Input
              id="company"
              label="Entreprise *"
              validation={defaultValidation}
              useForm={inputForm}
            />
            <Input
              id="situation"
              label="Poste occupé *"
              validation={defaultValidation}
              useForm={inputForm}
            />
          </div>
          <Select
            id="solution"
            label="Qualification de la demande *"
            useForm={inputForm}
            options={[
              { value: "Demande d'informations", label: "Demande d'informations" },
              ...solutions.map((solution) => {
                return { value: solution.title, label: solution.title };
              })
            ]}
          />
          <Select
            id="contact"
            label="Moyen de contact *"
            useForm={inputForm}
            options={[
              { value: "Email", label: "Email" },
              { value: "Calendly", label: "Calendly" },
              { value: "Téléphone", label: "Téléphone" },
              { value: "LinkedIn", label: "LinkedIn" }
            ]}
          />
          <h2>Vous contacter</h2>
          <div className="row-1000">
            <Input
              id="email"
              label="Adresse mail *"
              validation={emailValidation}
              useForm={inputForm}
            />
            <Input
              id="phone"
              label="Numéro de téléphone *"
              validation={phoneValidation}
              useForm={inputForm}
            />
          </div>
          <h2>Je planifie mon rendez-vous</h2>
          <div className="row-1000 align-center">
            <PopupWidget
              url="https://calendly.com/vitale-performance-sante"
              rootElement={document.getElementById("root")}
              text="Prendre Rendez vous"
            />
            <ReCAPTCHA
              sitekey="6LcDaksqAAAAANk6cmduWCKhIDg6YzH8HtFna56d"
              onChange={onCaptchaChange}
            />
          </div>
          <button className="dark-button" type="submit">
            Soumettre
          </button>
        </form>
      </div>
      <ToastContainer hideProgressBar />
    </div>
  );
}

export default Contact;
